const CustomCard = (props) => {
  return (
    <div className='second_second' onClick={props.onClick} style={{backgroundImage:`url(${props?.image})`}}>
      <h3>
        Custom <br />{" "}
        <p style={{ fontSize: "clamp(0.938rem,2vw,1.125rem)" }}>{props.head}</p>
      </h3>
    </div>
  );
};
export default CustomCard;
