import "./App.css";
import { ContextProvider } from "./context/store";
// import { BuggattiContext } from './context/store';
import AppRoutes from "./routes";

function App() {
  return (
    <>
      <ContextProvider>
        <AppRoutes />
      </ContextProvider>
    </>
  );
}
export default App;
