import { createContext, useReducer } from "react";
import { reducer } from "./reducer";

const store = {
  nftsdata: [],
  selectnft: [],
  walletconnect: false,
  web3: {},
  claimdata: [],
  claimdetails: [],
  confirmclaim: [],
  progress:false,
  loader:0,
  recipe:[],
  transactionHash:'',
  errorMessage:'',
  blendEnable:false,
  claimEnable:false,
  stakeEnable:false
};

export const BuggattiContext = createContext(store);

export const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, store);

  return (
    <BuggattiContext.Provider value={[state, dispatch]}>
      {children}
    </BuggattiContext.Provider>
  );
};
