import React, { useContext, useEffect, useState } from "react";
import lock from "../../updatedassets/nftLock.svg";
// import "../checkBoxBtn/CheckBoxBtn.css";
import CheckBoxBtn from "./../checkBoxBtn/CheckBoxBtn";
import dummyImage from "../../assests/1.png";
import ProgressiveImage from "react-progressive-graceful-image";

import "./nftcard.css";
import { BuggattiContext } from "../../context/store";

export const Nftcard = (props) => {
  const [imgLoading, setImgLoading] = useState(false);
//  (props?.attributes[11]?.value); 
  return (
    <div
      className='input-div'
      style={{
        borderBottomRightRadius: props.unstake ? "10px" : "auto",
        borderBottomLeftRadius: props.unstake ? "10px" : "auto",
        borderColor:`${
          props?.attributes[11]?.value == "UNCOMMON"
            ? "#30ff68"
            : props?.attributes[11]?.value == "RARE"
            ? "#d57eff"
            : props?.attributes[11]?.value == "EPIC"
            ? "#659bff"
            : props?.attributes[11]?.value == "LEGENDARY"
            ? "#f8b723"
            : "#98989a"
        }`
      }}>
      <ProgressiveImage src={props.image} placeholder={"Claim Nfts"}>
        {(src, loading) => {
          setImgLoading(loading);
          return imgLoading ? (
            <img
              className='image imgLoading'
              src={dummyImage}
              alt='Claim Nfts'
              height='100%'
              width='100%'
              style={{
                borderBottomRightRadius: props.unstake ? "10px" : "auto",
                borderBottomLeftRadius: props.unstake ? "10px" : "auto",
              }}
            />
          ) : (
            <img
              className='image imgLoaded'
              src={src}
              alt='Claim Nfts'
              height='100%'
              width='100%'
              style={{
                borderBottomRightRadius: props.unstake ? "10px" : "auto",
                borderBottomLeftRadius: props.unstake ? "10px" : "auto",
              }}
            />
          );
        }}
      </ProgressiveImage>
      {/* <img
        src={props.image}
        height='100%'
        width='100%'
        style={{
          borderBottomRightRadius: props.unstake ? "10px" : "auto",
          borderBottomLeftRadius: props.unstake ? "10px" : "auto",
        }}
        alt=''
      /> */}
      {(props.stakeScreen == true || props.claimScreen) && (
        <div className='claim_nft_no'>#{props.id}</div>
      )}
      {props.checkbox && props.id ? (
        <div className='claim_nft_chkbox'>
          {/* <CheckBoxBtn
            id={props.id}
            name="nftCard"
            onChange={() => {
              // props.handleChange(props.id);
            }}
          /> */}
          <div className='checkBoxBtn__round'>
            
          {/* <input
            onChange={(e) => {
              if(props.selectnft.length<2){
              props.onChange(props, e)
              }
              else{
                e.target.checked=false
                alert("You cannot select more than 2 nfts")
              }
            
            }
            
            }
            type='checkbox'
            id={props.id}
            // checked={props.checked}
          /> */}

          <input
            onChange={(e) => props.onChange(props, e)}
            type='checkbox'
            id={props.id}
            // checked={props.checked}
          />
            
            {/* <input
              onChange={(e) => props.onChange(props, e)}
              type='checkbox'
              id={props.id}
              disabled={props.claimScreen && props.selectnft.length>=2?props.selectnft.filter(e => e.id === props.id).length > 0?false:true:false}
              // checked={props.checked}
            /> */}
            <label htmlFor={props.id}></label>
          </div>
        </div>
      ) : (
        ""
      )}
      {props.unstake ? (
        <div
          className='bottom_stake'
          style={{
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
          }}>
          <p>89 Days 10 Hrs 12 Mins </p>
          <img src={lock} style={{ width: "20px" }} alt='' />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
