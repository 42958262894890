import React, { useContext, useEffect, useState } from "react";
import { SelectNft } from "../nftcard/selectnft";
import bag from "../../updatedassets/bag.png";
import Button from "../button/button";
import CheckBoxTickBtn from "../checkBoxTickBtn/CheckBoxTickBtn";
import { BuggattiContext } from "../../context/store";
import swal from "sweetalert";
import CustomCard from "./customproduct_card";
import Crossbody_Card from "./productcard";
import "./selectaproduct.css";
import upload_image from "../../assests/upload_image.svg";
import backpack from "../../assests/Backpack.png";
import Carry_On_Luggage from "../../assests/Carry-On_Luggage.png";
import Crossbody_Bag from "../../assests/Crossbody.png";
import Custom_Carry_On_Luggage from "../../assests/Custom_Carry-On_Luggage.png";
import Custom_Crossbody_Bag from "../../assests/Custom_Crossbody.png";
import Duffle_Bag from "../../assests/Duffle_Bag.png";
import Paddle_Board from "../../assests/Paddle_Board.png";
import axios from "axios";
import { ImageLoader } from "../loader/loader";

import Compressor from "compressorjs";
const Selectaproduct = ({ setSecondOption, data, secondoption }) => {
  const [show_acknowledge, setShow_acknowledge] = useState(false);
  const [file1, setFile1] = useState([]);
  const [file2, setFile2] = useState([]);
  const [select, setSelect] = useState(0);
  const [claim_details, setClaim_details] = useState([]);
  const [custom, setCustom] = useState();
  const [state, dispatch] = useContext(BuggattiContext);
  const [check, setCheck] = useState(true);
  //  (file1)
  let temp;

  useEffect(() => {
    if (show_acknowledge) return setShow_acknowledge(false);
  }, [secondoption]);

  useEffect(() => {
    if (secondoption == 2) {
      temp = [];
      setClaim_details([]);
      dispatch({
        type: "CLAIM_DETAILS",
        payload: null,
      });
      setFile1([]);
      setFile2([]);
      setSelect(0);
    }
  }, [secondoption]);

  const crossbody_handler = (i, index, type) => {
    temp = [...claim_details, { id: i?.id, claimType: type }];
    setClaim_details(temp);
    if (data?.length != index + 1) {
      setSelect(index + 1);
    } else {
      setSecondOption(3);
      dispatch({
        type: "CLAIM_DETAILS",
        payload: temp,
      });
    }
  };

  const custompfp = (i, index, type) => {
    setCustom({ id: i?.id, claimType: type });
    setShow_acknowledge(true);
    setSelect(index + 1);
  };

  const customimage = async (e) => {
    e.preventDefault();
    document.querySelector("#next_btn").style.display = "none";
    document.querySelector("#next_loader").style.display = "flex";
    if (
      (file1?.length !== 0
        ? file2.length === 0
          ? file1?.name?.match(/\.(jpg|jpeg|png)$/i)
          : file1?.name?.match(/\.(jpg|jpeg|png)$/i) &&
            file2?.name?.match(/\.(jpg|jpeg|png)$/i)
        : file2?.name?.match(/\.(jpg|jpeg|png)$/i)) ||
      (file2?.length !== 0
        ? file1.length === 0
          ? file2?.name?.match(/\.(jpg|jpeg|png)$/i)
          : file1?.name?.match(/\.(jpg|jpeg|png)$/i) &&
            file2?.name?.match(/\.(jpg|jpeg|png)$/i)
        : file1?.name?.match(/\.(jpg|jpeg|png)$/i))
    ) {
      let temp = [
        ...claim_details,
        {
          id: custom?.id,
          claimType: custom?.claimType,
          front: file1.length !== 0 ? await convertBase64(file1, "front", custom?.id) : "none",
          back: file2.length !== 0 ? await convertBase64(file2, "back", custom?.id) : "none",
        },
      ];
      setClaim_details(temp);
      setShow_acknowledge(false);
      if (data?.length == temp.length) {
        setSecondOption(3);
        dispatch({
          type: "CLAIM_DETAILS",
          payload: temp,
        });
      }
      setCheck(true);
      setFile1([]);
      setFile2([]);
    } else {
      swal({
        icon: "error",
        text: "Please Select an image",
      });
    }
  };

  const convertBase64 = (file, side, id) => {

    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        let body = {
          type: "uploadImage",
          nftId: id,
          frontOrBack: side,
          base64: fileReader.result
        };
        axios
          .post(
            "https://ytycn9jc0f.execute-api.us-east-1.amazonaws.com/production/submit", body
          )
          .then((res) => {
            // resolve(fileReader.result);
            let extension = file.type.split("/")[1];
            resolve(id + "_" + side + "." + extension);
            console.log(fileReader.result)
          })
          .catch((e) => {
            console.log(e);
          });
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <>
      <div
        style={{
          display: secondoption == 2 ? "flex" : "none",
          width: "100%",
          // display: "flex",
          justifyContent: "center",
        }}
      >
        {!show_acknowledge ? (
          data?.map((i, index) => (
            <div
              className="selectproduct_box"
              style={{ display: select == index ? "flex" : "none" }}
            >
              <div className="first_box">
                <h1>CLAIMING NFT</h1>
                <SelectNft
                  color={i?.attributes[11]?.value}
                  available={i?.id}
                  isImage={i?.image}
                />
              </div>
              <div className="second_box">
                <h1 style={{ color: "" }}>PRODUCT SELECTION</h1>
                {i?.attributes[11]?.value == "COMMON" ? (
                  <CustomCard
                    onClick={() => custompfp(i, index, "Custom Crossbody Bag")}
                    head={"Crossbody Bag"}
                    image={Custom_Crossbody_Bag}
                  />
                ) : i?.attributes[11]?.value == "EPIC" ? (
                  <>
                    <Crossbody_Card
                      onClick={() => crossbody_handler(i, index, "Duffle Bag")}
                      head={"Duffle Bag"}
                      image={Duffle_Bag}
                    />
                    <CustomCard
                      onClick={() =>
                        custompfp(i, index, "Custom Carry-on Luggage")
                      }
                      head={"Carry-on Luggage"}
                      image={Custom_Carry_On_Luggage}
                    />
                  </>
                ) : i?.attributes[11]?.value == "RARE" ? (
                  <>
                    <Crossbody_Card
                      onClick={() => crossbody_handler(i, index, "Backpack")}
                      head={"Backpack"}
                      image={backpack}
                    />
                    <CustomCard
                      onClick={() =>
                        custompfp(i, index, "Custom Crossbody Bag")
                      }
                      head={"Custom Crossbody Bag"}
                      image={Custom_Crossbody_Bag}
                    />
                  </>
                ) : i?.attributes[11]?.value == "LEGENDARY" ? (
                  <>
                    <Crossbody_Card
                      onClick={() =>
                        crossbody_handler(i, index, "Carry-on Luggage")
                      }
                      head={"Carry-on Luggage"}
                      image={Carry_On_Luggage}
                    />
                    <CustomCard
                      onClick={() =>
                        custompfp(i, index, "Custom Carry-on Luggage")
                      }
                      head={"Carry-on Luggage"}
                      image={Custom_Carry_On_Luggage}
                    />
                  </>
                ) : i?.attributes[11]?.value == "MYTHIC" ? (
                  <>
                    <Crossbody_Card
                      onClick={() =>
                        crossbody_handler(i, index, "Paddle Board")
                      }
                      head={"Paddle Board"}
                      image={Paddle_Board}
                    />
                  </>
                ) : (
                  <>
                    <CustomCard
                      onClick={() =>
                        custompfp(i, index, "Custom Crossbody Bag")
                      }
                      head={"Custom Crossbody Bag"}
                      image={Custom_Crossbody_Bag}
                    />
                    <Crossbody_Card
                      onClick={() =>
                        crossbody_handler(i, index, "Crossbody Bag")
                      }
                      head={"Crossbody Bag"}
                      image={Crossbody_Bag}
                    />
                  </>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="acknowledge_box">
            <form onSubmit={(e) => customimage(e)}>
              <h2 className="custom_heading">UPLOAD YOUR CUSTOM ART</h2>

              <div className="acknowledge_second ta-left">
                <div>
                  <p
                    style={{
                      textAlign: "center",
                      marginBottom: "10px",
                      color: "#fff",
                    }}
                  >
                    Front Image
                  </p>
                  <label
                    htmlFor="file1"
                    style={{ cursor: "pointer", position: "relative" }}
                  >
                    <input
                      type={"file"}
                      id="file1"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        new Compressor(e.target.files[0], {
                          quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
                          success: (compressedResult) => {
                            // compressedResult has the compressed file.
                            // Use the compressed file to upload the images to your server.
                            console.log(compressedResult)
                            setFile1(compressedResult);
                          },
                        });
                      }}
                      accept="image/*"
                    />

                    <img
                      src={upload_image}
                      style={{ width: "150px" }}
                      alt="bagImg"
                    />
                  </label>
                  <p>
                    File Name: <span>{file1?.name}</span>
                  </p>
                </div>
                <div>
                  <p
                    style={{
                      textAlign: "center",
                      marginBottom: "10px",
                      color: "#fff",
                    }}
                  >
                    Back Image
                  </p>
                  <label
                    htmlFor="file2"
                    style={{ cursor: "pointer", position: "relative" }}
                  >
                    <input
                      type={"file"}
                      id="file2"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        new Compressor(e.target.files[0], {
                          quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
                          success: (compressedResult) => {
                            // compressedResult has the compressed file.
                            // Use the compressed file to upload the images to your server.
                            console.log(compressedResult)
                            setFile2(compressedResult);
                          },
                        });
                      }}
                      accept="image/*"
                    />
                    <img
                      src={upload_image}
                      style={{ width: "150px" }}
                      alt="bagImg"
                    />
                  </label>
                  <p>
                    File Name: <span>{file2?.name}</span>
                  </p>
                </div>
              </div>
              <div
                className="acknowledge_second_2"
                style={{ marginTop: "30px" }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="acknowledge_second_2__round"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <CheckBoxTickBtn
                      onChange={() => setCheck(!check)}
                      confirmnft={check}
                    />
                  </div>
                  <p>I confirm that I own the IP rights to these images.</p>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <button>Send</button> */}
                  <div style={{ width: "11.25rem", height: "2.75rem" }}>
                    <div id='next_loader' style={{display: "none", justifyContent: "center", alignItems: "center"}}><ImageLoader/></div>
                  
                    <Button
                      id="next_btn"
                      name="NEXT"
                      green={"false"}
                      blue={true}
                      disabled={check}
                      type={"submit"}
                    />
                    
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  );
};
export default Selectaproduct;
