export const SelectNft = (props) => {
  return (
    <>
      <div
        className='claim_card_div'
        style={{
          borderColor: `${
            props.color == "UNCOMMON"
              ? "#30ff68"
              :  props.color == "RARE"
              ? "#d57eff"
              :  props.color == "EPIC"
              ? "#659bff"
              :  props.color == "LEGENDARY"
              ? "#f8b723"
              : "#98989a"
          }`,
        }}
        >
        <img
          src={props.isImage}
          style={{
            width: "100%",
            height: "100%",
          }}
          alt=''
        />
        <div className='claim_nft_no'>#{props.available}</div>
      </div>
    </>
  );
};
