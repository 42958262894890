import { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import { BuggattiContext } from "../../context/store";
import { Claim_slider_settings } from "../../utils/utils--claim";
import { ClaimBtn } from "../button/claimBtn";
import { Loader } from "../loader/loader";
import { Nftcard } from "../nftcard/nftcard";

const ClaimNft = ({ setSecondOption, secondOption }) => {
  const [state, dispatch] = useContext(BuggattiContext);
  const [selectnft, setSelectnft] = useState([]);
  const [loader, setLoader] = useState(true);
  const [claimdata, setClaimdata] = useState([]);
  useEffect(() => {
    isclaim();
  }, [state?.nftsdata?.data]);



  const nft = () => {
    console.log(selectnft)
    dispatch({
      type: "CLAIM_SELECT",
      payload: selectnft,
    });
    selectnft.length == 0 ? <></> : 
    setSecondOption(2);
    setSelectnft([])
  };

  const SelectNft = (item, e) => {
    let check = e.target.checked;
    let id = e.target.id;
    if (check) return setSelectnft([...selectnft, item]);
    else return setSelectnft(selectnft?.filter((i) => i.id !== id));
  };
  const isclaim = () => {
    const setData = () => {
      setClaimdata(arr);
      
    };
    let arr = [];
    state?.nftsdata?.data?.forEach(async (i, index, array) => {
      if (
        (await state?.web3?.bugattiContract?.methods.claimed(i?.id).call()) ==
        false
      ) {
        arr.push(i);
      }
      if (index == array.length - 1) {
        setData();
      }
    });
    setLoader(false);
  };
  return (
    <>
      {secondOption == 1 ? (
        <div className='blend-recipe blend-recipe__claimnfts'>
          {loader ? (
            <Loader />
          ) : claimdata.length == 0 ? (
            <div
              className='selecting_nfts'
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}>
              <h3 style={{ textAlign: "center" }}>
                There are no NFTs to Claim
              </h3>
            </div>
          ) : (
            <>
              <div className='selecting_nfts'>
                <h3>SELECT YOUR NFTS TO CLAIM</h3>

                <Slider {...Claim_slider_settings}>
                  {claimdata.map((i) => {
                    // setCount(count + 1);
                    return (
                      <Nftcard
                        {...i}
                        key={i.id}
                        id={i.id}
                        onChange={(item, e) => SelectNft(item, e)}
                        setSecondOption={setSecondOption}
                        claimScreen={true}
                        checkbox={true}
                        selectnft={selectnft}
                      />
                    );
                  })}
                </Slider>
              </div>
            </>
          )}

          <div className='claim_info'>
            <div className='claim_info__buttonDiv'>
              <ClaimBtn
                onClick={() => {
                  nft();
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default ClaimNft;
