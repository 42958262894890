import "./CheckBoxTickBtn.css";

const CheckBoxTickBtn = ({ onChange, confirmnft, name }) => {
  return (
    <div className='checkBoxTickBtn__round'>
      <input
        type='checkbox'
        id={name || "checkbox"}
        value={confirmnft}
        onChange={onChange}
      />
      <label htmlFor={name || "checkbox"}></label>
    </div>
  );
};
export default CheckBoxTickBtn;
