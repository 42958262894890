import Slider from "react-slick"
import { BlendUtils, settings } from "../../utils/utils--blends"
import RecipeCard from "./recipecard"

export const RecipeSlider = ({setFirstOption,setSelectRecipe}) =>{
const recipelist = BlendUtils()

    return(
        <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          height: "65vh",
        }}>
        <div className='blend-recipe'>
          <Slider {...settings}>
            {recipelist.map((i) => (
              <RecipeCard
                {...i}
                key={i.id}
                setFirstOption={setFirstOption}
                setSelectRecipe={setSelectRecipe}
              />
            ))}
          </Slider>
        </div>
      </div>
    )
}