import { useState } from "react";
import { SmallLoader } from "../loader/loader";
import "./claimBtn.css";
const Button = (props) => {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };
//  (isHover)
  const btnstyle = {
    borderRadius: props.borderRadius || 7,
    width: "100%",
    // height: "100%",
    marginTop: "0.75rem",
    backgroundColor:
      isHover && props.white
        ? "#00adf0"
        : props.blue
        ? isHover
          ? "rgb(1, 127, 176)"
          : "rgb(1, 162, 225)"
        : props.green
        ? "#30ff68"
        : "#fff",
    border: "none",
    fontSize: "clamp(1.2rem, 1.7vw, 2rem)",
    cursor: "pointer",
    fontFamily: "Anton",
    color: props.white
      ? "#12141d"
      : props.blue
      ? "#fff"
      : props.green
      ? "##12141d"
      : "##12141d",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    button: "4px 0px",
    transform: isHover ? "scale(1.05)" : "",
    padding: "5px 10px",
    transition: "0.2s all ease-in-out",

  };

  return (
    <>
      <button
        {...props}
        style={btnstyle}
        className={props.ship_btn_div__button}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        >
        {props.name}
        {props.name == "Connecting" ? <SmallLoader /> : <></>}
      </button>
    </>
  );
};
export default Button;
