import Slider from "react-slick";
import StakeNftCard from "../nftcard/stakenft";
import { useEffect, useContext, useState } from "react";
import { BuggattiContext } from "../../context/store";
import stakingAbi from "../../config/stakingAbi.json";
import AWS from "aws-sdk";
import CheckBoxTickBtn from "../checkBoxTickBtn/CheckBoxTickBtn";
import lock from "../../assests/lock.svg";

import { StakingContractAddress as contractAddress } from "../../config/constants";

const Unstake = ({ setFirstOption }) => {
  AWS.config.region = "us-east-1"; // Region
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "us-east-1:348c044f-13ff-426e-9cf8-3022ec10b684",
  });

  const [state, dispatch] = useContext(BuggattiContext);
  const [unstakeData, setData] = useState("");
  const [stakedNft, setStakedNft] = useState([]);
  const [stakedTokens, setStakedTokens] = useState([]);
  const [loading, setLoading] = useState(true);
  const [minStake, setMinStake] = useState(0);
  const [confirmstake, setConfirmstake] = useState(true);

  useEffect(() => {
    getUnStaked();
  }, []);

  const getUnStaked = async () => {
    let stakingContract = await new state.web3.walletInstance.eth.Contract(
      stakingAbi,
      contractAddress
    );
    const arr = [];
    let min = await stakingContract.methods.minStakeDuration().call();
    setMinStake(min);
    const un = await stakingContract.methods
      .getStakedTokens(state.web3.walletAddress)
      .call();
    setStakedNft(un);
    un.forEach((item) => {
      arr.push(item.tokenId);
    });
    const params = {
      FunctionName: "bugatti-get-metadata-multiple",
      Payload: JSON.stringify({
        id: arr,
      }),
    };
    const result = await new AWS.Lambda().invoke(params).promise();
    let data = JSON.parse(result.Payload);
    setData(data);
    setLoading(false);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrow: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          arrow: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrow: true,
        },
      },
    ],
  };

  const handleChange = (token) => {
    let id = parseInt(token)
    if (stakedTokens.includes(id) == false) {
      setStakedTokens([...stakedTokens, parseInt(id)])
      // const index = stakedTokens.indexOf(token);
      // stakedTokens.splice(index, 1);
      // setStakedTokens(stakedTokens.filter((item) => item !== parseInt(token)));
    } else {
      setStakedTokens(stakedTokens?.filter((i) => i !== parseInt(id)))
      // setStakedTokens((stakedTokens) => [...stakedTokens, parseInt(token)]);
    }
  };

  const unStakeNfts = async () => {
    if (stakedTokens?.length != 0) {
      let stakingContract = await new state.web3.walletInstance.eth.Contract(
        stakingAbi,
        contractAddress
      );
      stakingContract.methods
        .bulkUnStake(stakedTokens)
        .estimateGas({ from: state.web3.walletAddress })
        .then(function (gasAmount) {
          stakingContract.methods
            .bulkUnStake(stakedTokens)
            .send({ from: state.web3.walletAddress }, function (error, tx) {
              if (error) {
              } else {
              }
            });
        })
        .catch(function (error) {
          // swal({
          //   title: "Error Found",
          //   text: "Insufficient Funds For Transaction in Wallet",
          //   type: "error",
          //   showCancelButton: false,
          //   confirmButtonClass: "btn-danger",
          //   confirmButtonText: "Ok",
          //   closeOnConfirm: false
          // });
        });
    }
  };

  return (
    loading == false && (
      <>
        <div className='nft_stake_bottom '>
          <div className={unstakeData?.length == 0?"unstake_empty unstake_div nft_stake":'nft_stake unstake_div'}>
            {unstakeData?.length == 0?
          <h3 style={{textAlign:"center"}}>There are no NFTs to Unstake</h3>  
          :
          <>
            <h3>YOUR STAKED NFTS</h3>
          <div style={{ marginBlock: "1rem" }}>
          <Slider {...settings}>
            {unstakeData?.map((item, index) => (
              item?.attributes[11]?.value!="HIDDEN" &&
              <StakeNftCard
                handleChange={handleChange}
                id={item.id}
                stakeScreen={true}
                isImage={item.image}
                checkbox={true}
                minDuration={minStake}
                stakedTime={stakedNft[index].timeAdded}
                unstake={true}
                color={item?.attributes[11]?.value}
              />
            ))}
          </Slider>
        </div>
        {/* <div
              className='unstake_nft__checkBoxBtnDiv'
              style={{
                display: "flex",
                alignItems: "center",
                padding: "10px 0px",
              }}>
              <CheckBoxTickBtn
                name={"un_stake"}
                confirmnft={confirmstake}
                onChange={() => setConfirmstake(confirmstake)}
              />
              <p style={{ marginLeft: "10px" }}>
                Some Informative text. Somethings are meant to happen in life so
                better shut up and accept whats happening instead or rant. If
                you don't like it fix it.
              </p>
            </div> */}
          </>
          }
          </div>         
          <div
            className='upper-div-2 unstake-btn staking-fix'
            onClick={() => (stakedTokens.length==0 ? console.log("No tokens selected to Unstake") : unStakeNfts())}>
            {stakedTokens.length==0 || state?.stakeEnable == false ? (
              <>
                <div className='gradient-overlay'></div>
                <img src={lock} />
                <h1>UNSTAKE</h1>
              </>
            ) : (
              <h1 style={{backgroundImage:"none"}}>UNSTAKE</h1>
            )}
          </div>
        </div>
      </>
    )
  );
};
export default Unstake;
