import useMediaQuery from "../media-query";
import axios from "axios";
import React from "react";
const LeaderBoard = () => {
  const [leaderboard, setLeaderBoard] = React.useState([]);

  React.useEffect(() => {
    axios
      .get(
        "https://ytycn9jc0f.execute-api.us-east-1.amazonaws.com/production/stakingleaderboard",
        {}
      )
      .then((res) => {
        console.log(res.data);
        setLeaderBoard(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const textstring = (text) => {
    if (text?.length > 5) {
      return (
        text?.substring(0, 5) +
        "..." +
        text?.substring(text?.length - 5, text?.length)
      );
    } else {
      return text;
    }
  };
  const mediaquery = useMediaQuery("(max-width: 650px)");
  return (
    <>
      <div className="leader_board">
        <div className="leader_board_upper">
          <h2>STAKING LEADERBOARDS</h2>
        </div>

        <div
          style={{
            overflowX: "auto",
            border: "1px solid #9b9c9e",
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
        >
          <table
            className="table table-striped"
            style={{ borderCollapse: "collapse" }}
          >
            <thead>
              <tr style={{ border: "1px solid #9b9c9e" }}>
                <td>#NO</td>
                <td style={{ textAlign: "left" }}>WALLET ADDRESS</td>
                <td>TRAVEL POINTS</td>
              </tr>
            </thead>
            <tbody>
              {leaderboard?.map((item, i) => (
                <tr>
                  <td style={{fontFamily:"Anton"}}>{i}</td>
                  <td style={{ textAlign: "left",fontFamily:"Anton" }}>
                    {!mediaquery
                      ? item.walletAddress
                      : textstring(item.walletAddress)}
                  </td>
                  <td style={{fontFamily:"Anton"}}>{item.points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default LeaderBoard;
