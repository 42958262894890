import React, { useContext, useState } from "react";
import Navbar from "../component/navbar/navbar";
import Upperbar from "../component/uppperbar/uppperbar";
import "./Blend.css";
import BlendFactory from "../component/blend_factory/blend_factory";
import BlendConfirm from "../component/blendconfirm/blendconfirm";
import Progress_bar from "../component/progress_bar/progress_bar";
import { BuggattiContext } from "../context/store";
import { RecipeSlider } from "../component/recipecard/recipeslider";

const Blend = () => {
  const [state] = useContext(BuggattiContext);
  const [firstOption, setFirstOption] = useState(1);
  const [selectRecipe, setSelectRecipe] = useState([]);
  const [status, setstatus] = useState("");
  return (
    <>
      <div>
        <Navbar />
        <div className='blend-content'>
          <Upperbar firstOption={firstOption} setFirstOption={setFirstOption} />
          {firstOption == 1 ? (
        <RecipeSlider setFirstOption={setFirstOption} setSelectRecipe={setSelectRecipe}/>
          ) : ( <></>)}
            <BlendFactory
              setFirstOption={setFirstOption}
              selectRecipe={selectRecipe}
              data={state?.nftsdata}
              firstOption={firstOption}
            />
            <BlendConfirm
              setFirstOption={setFirstOption}
              selectRecipe={selectRecipe}
              setstatus={setstatus}
              firstOption={firstOption}
            />
          <div
            className='blend-factory'
            style={{
              display: firstOption == 4 ? "flex" : "none",
              marginTop: "150px",
            }}>
            <div className='blend-factory__progressBar'>
              <Progress_bar status={status} />
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
};

export default Blend;
