import { useContext } from "react";
import { BuggattiContext } from "../context/store";
import uncommon from "./../assests/uncommon.png";
import rare from "./../assests/rare.png";
import epic from "./../assests/epic.png";
import legendary from "./../assests/legendary.png";
import mythic from "./../assests/mythic.png";

export const BlendUtils = () =>{
const [state] = useContext(BuggattiContext)

   const recipelist = [
    {
      id: 1,
      color: "#30ff68",
      icon: uncommon,
      available: state?.recipe[0],
      type: "UNCOMMON",
      item: ["2 Common"],
    },
    {
      id: 2,
      color: "#d57eff",
      icon: rare,
      available: state?.recipe[1],
      type: "RARE",
      item: ["3 Common", "1 Uncommon"],
    },
    {
      id: 3,
      color: "#659bff",
      icon: epic,
      available: state?.recipe[2],
      type: "EPIC",
      item: ["4 Common", "4 Uncommon"],
    },
    {
      id: 4,
      color: "#fcba22",
      icon: legendary,
      available: state?.recipe[3],
      type: "LEGENDARY",
      item: ["1 Common", "1 Uncommon", "2 Rare", "3 Epic"],
    },
    {
      id: 5,
      color: "#ef3a5b",
      icon: mythic,
      available: state?.recipe[4],
      type: "MYTHIC",
      item: ["1 Common", "1 Uncommon", "2 Rare", "1 Epic", "2 Legendary"],
    },
  ];
return recipelist

};
export const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 740,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],

}

export const blend_factory_settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 8,
  slidesToScroll: 1,
        initialSlide: 1,

  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite:true,
      },
    },
  ],
};