import { useState, useMemo, useContext } from "react";
import Button from "../button/button";
import Input from "../input";
import countryList from "react-select-country-list";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";
import "./shipping_form.css";
import { BuggattiContext } from "../../context/store";

const Shippingform = ({ setSecondOption, secondOption }) => {
  const [value, setValue] = useState();
  const [country, setCountry] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [shippingdata, setShipping] = useState([]);
  const [state, dispatch] = useContext(BuggattiContext);

  const changeHandler = (e) => {
    setCountry(e);
    setShipping({ ...shippingdata, country: e.label });
  };

  const phoneNumberValue = (e) => {
    setShipping({ ...shippingdata, mobileNumber: e });
  };

  const inputhandler = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setShipping({ ...shippingdata, [name]: value });
  };
  const submithandler = (e) => {
    e.preventDefault();
    dispatch({
      type: "CONFIRM_CLAIM",
      payload: { shippingdata, productDetails: state?.claimdetails },
    });
    setSecondOption(4);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "50px",
      height: "50px",
      borderRadius: "10px",
      color: "black !important",
      border: "none",
      boxShadow: "none",
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: "50px",
      padding: "0 6px",
      fontSize: "clamp(0.9rem,4vw,1.25rem)",
      fontFamily: "Varela-Regular",
      fontWeight: "400 !important",
      color: "black !important",
      border: "none",
      boxShadow: "none",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      color: "black !important",
      border: "none",
      boxShadow: "none",
    }),
  };

  return (
    <>
      <div
        style={{
          display: secondOption == 3 ? "flex" : "none",
          justifyContent: "center",
          width: "100%",
        }}>
        <div className='shipping_form_box'>
          <div className='sipping_form_box__heading'>
            <h3>SHIPPING ADDRESS</h3>
          </div>
          <form onSubmit={(e) => submithandler(e)} style={{ width: "100%" }}>
            <div className='multi-input'>
              <div>
                <Input
                  placeholder='First name'
                  required={true}
                  name='firstName'
                  onChange={(e) => inputhandler(e)}
                />
              </div>
              <div>
                <Input
                  placeholder='Last name'
                  required={true}
                  name='lastName'
                  onChange={(e) => inputhandler(e)}
                />
              </div>
            </div>
            <div className='single_input single_input1'>
              <Input
                placeholder='Email Address'
                required={true}
                name='email'
                onChange={(e) => inputhandler(e)}
              />
            </div>
            <div
              className='single_input'
              style={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                borderColor:
                  "-internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133))",
              }}>
              <PhoneInput
                stlye={{
                  backgroundColor: "#fff",
                  width: "100%",
                  fontFamily: "Varela-Regular",
                  height: "100%",
                  padding: "0px 10px",
                  outline: "none",
                  fontSize: "1.125rem",
                }}
                placeholder='123 123 12'
                defaultCountry='US'
                // value={value}
                onChange={(e) => phoneNumberValue(e)}
                required={true}
              />
            </div>
            <div className='single_input'>
              <Input
                placeholder='House/Apartment, Street, Area'
                required={true}
                name='address'
                onChange={(e) => inputhandler(e)}
              />
            </div>
            <div className='multi-input-2'>
              <div>
                <Input
                  placeholder='City'
                  required={true}
                  name='city'
                  onChange={(e) => inputhandler(e)}
                />
              </div>
              <div className='multi-input-2__countrySelectDiv'>
                <Select
                  className='multi-input-2__countrySelect'
                  options={options}
                  value={country}
                  onChange={changeHandler}
                  placeholder='Country'
                  required={true}
                  styles={customStyles}
                />
              </div>
              <div>
                <Input
                  placeholder='zip'
                  required={true}
                  name='zip'
                  onChange={(e) => inputhandler(e)}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}>
              <div className='ship_btn_div'>
                <Button
                  name='CONFIRM'
                  green={false}
                  blue={true}
                  type='submit'
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default Shippingform;
