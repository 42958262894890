import { useState } from "react";
import "./CheckBoxBtn.css";

const CheckBoxBtn = ({ name, id, onChange, color }) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div className='checkBoxBtn__round'>
      <input
        type='checkbox'
        id={`${name + id}` || id}
        onChange={() => {
          onChange();
          setIsChecked(!isChecked);
        }}
      />
      <label
        htmlFor={`${name + id}` || id}
        style={{
          backgroundColor: isChecked ? `${color || "#049ad5"}` : "",
        }}></label>
    </div>
  );
};
export default CheckBoxBtn;
