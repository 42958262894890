import "./footer.css";

import footerImg from "./../../assests/nalikesLogo.png";

const Footer = () => {
  return (
    <div className='footer'>
      {/* <div className='flexCenter'> */}
      <center>
        <a
          href='https://www.nalikes.com/'
          target='_blank'
          rel='noreferrer'
          style={{
            textDecoration: "none",
            display: "flex",
            height: "40px",
            justifyContent: "center",
            alignItems: "center",
            color: "rgb(233, 233, 233)",
          }}>
          <h4 className='flexCenter'>
            Powered By
            <img src={footerImg} alt='' />
          </h4>
        </a>
      </center>
      {/* </div> */}
    </div>
  );
};

export default Footer;
