import Slider from "react-slick";
import { useContext, useEffect, useState } from "react";
import Input from "../input";
import { Nftcard } from "../nftcard/nftcard";
import { BuggattiContext } from "../../context/store";
import stakingAbi from "../../config/stakingAbi.json";
import CheckBoxTickBtn from "../checkBoxTickBtn/CheckBoxTickBtn";
import { Loader, MediumLoader, SmallLoader } from "../loader/loader";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import lock from "../../assests/lock.svg";
import swal from "sweetalert";
import AWS from "aws-sdk";

import { StakingContractAddress as contractAddress } from "../../config/constants";

const StakeNft = ({ setFirstOption }) => {
  const [state, dispatch] = useContext(BuggattiContext);
  const [stakedTokens, setStakedTokens] = useState([]);
  const [success, setSuccess] = useState(false);
  const [loader, setLoader] = useState(false);
  const [value, setValue] = useState([]);
  const [confirmstake, setConfirmstake] = useState(true);
  const [smallloader, setSmallLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 3000);
  }, [state]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleChange = (props, token) => {
    if (stakedTokens.includes(parseInt(token.target.id)) == 1) {
      const index = stakedTokens.indexOf(parseInt(token.target.id));
      stakedTokens.splice(index, 1);
      setStakedTokens(
        stakedTokens.filter((item) => item !== parseInt(token.target.id))
      );
    } else {
      setStakedTokens((stakedTokens) => [
        ...stakedTokens,
        parseInt(token.target.id),
      ]);
    }
  };

  const inputhandler = (e) => {
    let values = e.target.value;
    let name = e.target.name;
    setValue({ ...value, [name]: values });
  };

  const refreshdata = async () => {
    let tokensOfOwner = await state?.web3?.bugattiContract.methods
      .tokensOfOwner(state?.web3?.walletAddress)
      .call();
    const params = {
      FunctionName: "bugatti-get-metadata-multiple",
      Payload: JSON.stringify({
        id: tokensOfOwner,
      }),
    };
    const result = await new AWS.Lambda().invoke(params).promise();
    let data = JSON.parse(result.Payload);
    console.log(data)
    dispatch({
      type: "DATA",
      payload: { data }
    });
  };

  const stackcall = () => {
    let body = {
      type: "stake",
      twitterhandle: value?.twitterhandle,
      walletAddress: state?.web3?.walletAddress,
      email: value?.email,
      date: "",
    };
    axios
      .post(
        "https://ytycn9jc0f.execute-api.us-east-1.amazonaws.com/production/submit",
        body
      )
      .then((res) => {
        console.log(res, "1");
        setSmallLoader(false);
        refreshdata();
        // setFirstOption(2);
      })
      .catch((e) => console.log(e));
  };

  const submithandler = () => {
    setSmallLoader(true);
    if (value?.email == undefined || value?.twitterhandle == undefined || stakedTokens?.length == 0 || state?.stakeEnable == false) {
      swal({
        icon: "error",
        text: "Please Input All Fields",
      });
    } else {
      stakeNfts();
    }
  };
  
  const stakeNfts = async () => {
    let stakingContract = await new state.web3.walletInstance.eth.Contract(
      stakingAbi,
      contractAddress
    );

    let approvalCheck = await state.web3.bugattiContract.methods.isApprovedForAll(state.web3.walletAddress, contractAddress).call()
    if (!approvalCheck) {
      state.web3.bugattiContract.methods
        .setApprovalForAll(contractAddress, true)
        .estimateGas({ from: state.web3.walletAddress })
        .then(function (gasAmount) {
          state.web3.bugattiContract.methods
            .setApprovalForAll(contractAddress, true)
            .send({ from: state?.web3?.walletAddress }, function (error, tx) {
              if (error) {
                console.log(error);
                setSmallLoader(false);
              }
            }).then(function (receipt) {
              stakingContract.methods
                .bulkStake(stakedTokens)
                .estimateGas({ from: state.web3.walletAddress })
                .then(function (gasAmount) {
                  // console.log("res");
                  stakingContract.methods
                    .bulkStake(stakedTokens)
                    .send(
                      { from: state.web3.walletAddress },
                      function (error, tx) {
                        if (error) {
                          setSmallLoader(false);
                          console.log(error);
                        } else if (tx) {
                          stackcall();
                        }
                      }
                    );
                })
                .catch(function (error) {
                  console.log(error);
                  setSmallLoader(false);
                  // swal({
                  //   title: "Error Found",
                  //   text: "Insufficient Funds For Transaction in Wallet",
                  //   type: "error",
                  //   showCancelButton: false,
                  //   confirmButtonClass: "btn-danger",
                  //   confirmButtonText: "Ok",
                  //   closeOnConfirm: false
                  // });
                });
            })
        })
        .catch(function (error) {
          console.log(error);
          // swal({
          //   title: "Error Found",
          //   text: "Insufficient Funds For Transaction in Wallet",
          //   type: "error",
          //   showCancelButton: false,
          //   confirmButtonClass: "btn-danger",
          //   confirmButtonText: "Ok",
          //   closeOnConfirm: false
          // });
        });
    }
    else {
      stakingContract.methods
        .bulkStake(stakedTokens)
        .estimateGas({ from: state.web3.walletAddress })
        .then(function (gasAmount) {
          // console.log("res");
          stakingContract.methods
            .bulkStake(stakedTokens)
            .send(
              { from: state.web3.walletAddress },
              function (error, tx) {
                if (error) {
                  setSmallLoader(false);
                  console.log(error);
                } else if (tx) {
                  stackcall();
                }
              }
            );
        })
        .catch(function (error) {
          console.log(error);
          setSmallLoader(false);
          // swal({
          //   title: "Error Found",
          //   text: "Insufficient Funds For Transaction in Wallet",
          //   type: "error",
          //   showCancelButton: false,
          //   confirmButtonClass: "btn-danger",
          //   confirmButtonText: "Ok",
          //   closeOnConfirm: false
          // });
        });
    }
  };

  return (
    <>
      {state?.nftsdata?.data?.length == 0 ?
        // <div></div>
        <div className='nft_stake' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <h3 style={{ textAlign: "center" }}>There are no NFTs to Stake</h3>
        </div>
        :
        <div className='nft_stake'>
          {loader ? (
            <Loader />
          ) : (
            <>
              <h3>SELECT NFTS TO STAKE</h3>
              <div>
                <Slider {...settings}>
                  {state?.nftsdata?.data?.map((item, index) => (
                    <Nftcard
                      {...item}
                      onChange={handleChange}
                      id={item.id}
                      image={item.image}
                      stakeScreen={true}
                      checkbox={true}
                    // item={item}
                    />
                  ))}
                </Slider>
              </div>
            </>
          )}
        </div>
      }
      <div className='nft_stake_bottom'>
        <div className='nft_stake'>
          <h3>SELECTED {stakedTokens.length} NFTS</h3>
          <div className='stake_input'>
            <div style={{ width: "38%", height: "50px" }}>
              <Input
                reuired={true}
                name='twitterhandle'
                placeholder={"DiscordID#0000"}
                onChange={(e) => inputhandler(e)}
              />
            </div>

            <div style={{ width: "60%", height: "50px" }}>
              <Input
                reuired={true}
                name='email'
                placeholder={"Email Address"}
                onChange={(e) => inputhandler(e)}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "1rem",
            }}>
            <CheckBoxTickBtn
              name={"stake"}
              confirmnft={confirmstake}
              onChange={() => setConfirmstake(!confirmstake)}
            />
            <p style={{ marginLeft: "10px" }}>
              By participating in NFT Staking, you acknowledge that your NFTs will be locked for the minumum staking duration of 118 days.
            </p>
          </div>
        </div>
        <div className='upper-div-2 staking-fix'
          style={{
            backgroundImage: smallloader ? "none" : "",
            alignItems: smallloader ? "center" : "",
          }}
          onClick={() => {
            value?.email == undefined || value?.twitterhandle == undefined || stakedTokens?.length == 0 || state?.stakeEnable == false || confirmstake ? (
              <></>
            ) : (
              submithandler()
            );
          }}>
          {smallloader ? (
            <MediumLoader />
          ) : value?.email == undefined || value?.twitterhandle == undefined || stakedTokens?.length == 0 || state?.stakeEnable == false || confirmstake ? (
            <>
              <div className='gradient-overlay'></div>
              <img src={lock} />
              <h1>STAKE</h1>
            </>
          ) : (
            <h1 style={{ backgroundImage: "none" }}>STAKE</h1>
          )}
        </div>
      </div>
    </>
  );
};
export default StakeNft;
