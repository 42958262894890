import React, { useContext } from "react";
import { useState } from "react";
import Upperbar from "../component/uppperbar/uppperbar";
import Navbar from "../component/navbar/navbar";
import "./Claim.css";
import Selectaproduct from "../component/selectingproduct/selectinaproduct";
import Shippingform from "../component/shipping_form/shipping_form";
import ConfirmBox from "../component/confirm_box/confirm_box";
import { BuggattiContext } from "../context/store";
import Progress_bar from "../component/progress_bar/progress_bar";
import ClaimNft from "../component/claim_nft/claim_nft";

export const Claim = () => {
  const [claim, setClaim] = useState(true);
  const [secondOption, setSecondOption] = useState(1);
  const [state, dispatch] = useContext(BuggattiContext);
  const [status, setStatus] = useState("");

  return (
    <>
      <div>
        <Navbar />
        <div className='claim-content'>
          <Upperbar
            isClaim={claim}
            secondOption={secondOption}
            setSecondOption={setSecondOption}
          />
          <ClaimNft
            setSecondOption={setSecondOption}
            secondOption={secondOption}
          />
          <Selectaproduct
            setSecondOption={setSecondOption}
            data={state?.claimdata}
            secondoption={secondOption}
          />
          <Shippingform
            setSecondOption={setSecondOption}
            secondOption={secondOption} />

          <ConfirmBox
            setSecondOption={setSecondOption}
            status={status}
            setStatus={setStatus}
            secondOption={secondOption}
          />
          <div
            className='blend-factory'
            style={{
              display: secondOption == 5 ? "flex" : "none",
              marginTop: "150px",
            }}>
            <div className='blend-factory__progressBar'>
              <Progress_bar
                status={status}
              />
            </div>
          </div>
        </div>
      </div>

    </>
  );
};
