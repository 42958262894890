import React from "react";
import "./claimBtn.css";

export const ClaimBtn = (props) => {
  const claimstyle = {
    border: "1px solid #47a8af",
    borderRadius: "7px",
    color: "#fff",
    background: "#222637",
    padding: "10px 70px",
    fontSize: 20,
    cursor: "pointer",
    fontFamily: "Anton",
    textTransform: "uppercase",
    transition: "background-color 0.2s ease-in-out",
  };
  return (
    <button {...props} style={claimstyle} className='claimBtn'>
      {props.name || "Claim"}
    </button>
  );
};