import { useContext, useState } from "react";
import Slider from "react-slick";
import plus from "../../assests/plus.svg";
import { BuggattiContext } from "../../context/store";
import lock from "../../assests/lock.svg";
import AWS from "aws-sdk";
import Web3 from "web3";
import contractAbi from "../../config/abi.json";
import { NftContractAddress as contractAddress } from "../../config/constants";

// import CheckBoxTickBtn from "../checkBoxTickBtn/CheckBoxTickBtn";
import CheckBoxTickBtn from "../checkBoxTickBtn/CheckBoxTickBtn";

import "../blend_factory/blend_factory.css";

const BlendConfirm = ({
  setFirstOption,
  selectRecipe,
  setstatus,
  firstOption,
}) => {
  const [state, dispatch] = useContext(BuggattiContext);
  const [confirmnft, setConfirmnft] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
          arrow: true,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          arrow: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          arrow: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrow: true,
          infinite: true,
        },
      },
    ],
  };

  const start = () => {
    dispatch({
      type: "PROGRESS",
      payload: true,
    });
    let percent = 0;
    var intervel = setInterval(function () {
      if (percent >= 100) {
        setstatus("SUCCESS");
        clearInterval(intervel);
      }
      dispatch({
        type: "LOADER",
        payload: percent++,
      });
    }, 100);
  };

  // code snippet which i had paste:


  // blend
  async function blend() {
    var percent = 0
    dispatch({
      type: "PROGRESS",
      payload: true,
    });
    AWS.config.region = "us-east-1"; // Region
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: "us-east-1:348c044f-13ff-426e-9cf8-3022ec10b684",
    });
    var lambda = new AWS.Lambda();
    let contract = await new state.web3.walletInstance.eth.Contract(
      contractAbi,
      contractAddress
    );

    let recipe = selectRecipe.id
    let nfts = []

    state?.selectnft?.recipes?.forEach(item=>{
      nfts.push(item.id)
    })

    // RECIPE ID: 1 to 5
    // NFT TOKENID ARRAY: [1,31,250]
    // WALLETADDRESS
    console.log({ walletAddress, recipe, nfts });

    const hashedMessage = Web3.utils.soliditySha3(
      { type: "address", value: state.web3.walletAddress },
      { type: "uint256", value: recipe },
      { type: "uint256[]", value: nfts }
    );
    state.web3.walletInstance.eth.personal.sign(
      hashedMessage,
      state.web3.walletAddress
    ).then((authSignature)=>{
      dispatch({
        type: "LOADER",
        payload: percent+=20,
      });
  
      var params = {
        walletAddress: state.web3.walletAddress,
        recipeId: recipe,
        nfts: nfts,
        authSignature: authSignature,
      };
  
      lambda
        .invoke({
          FunctionName: "bugatti-blends-signature",
          Payload: JSON.stringify(params),
        })
        .promise()
        .then((res) => {
          var output = JSON.parse(res.Payload);
          if (output.error != null) {
            console.log(output);
            
            setstatus("ERROR");
            // document.querySelector("#output").innerHTML = output.error;
          } else {
            // output = server signature
            console.log(output);
            // document.querySelector("#output").innerHTML = output;
  
            // CONTRACT BLEND CALL HERE
            dispatch({
              type: "LOADER",
              payload: 70,
            });
            
  
            contract.methods.blend(recipe, nfts, output).send({ from: state?.web3?.walletAddress },
              function (error, tx) {
                if (error) {
                  // show error
                  console.log(error);
                  setstatus("ERROR");
                } else if(tx) {
                  console.log(tx)
                  dispatch({
                    type: "LOADER",
                    payload: 100,
                  });

                  dispatch({
                    type: "TRANSACTION_HASH",
                    payload: tx
                  });
                  
                  
          setstatus("SUCCESS");
                }
              }
            );
            
          }
        })
        .catch((err) => {
          console.log(err);
          setstatus("ERROR");
        });
    }).catch((err)=>{
      console.log(err)
      setstatus("ERROR");
    })
   
  }

  return (
    <>
      <div
        className="blend-factory blendConfirm"
        style={{
          display: firstOption == 3 ? "flex" : "none",
          marginTop: "100px",
        }}
      >
        <div className="upper-div">
          <div
            className="ingredient-div"
            style={{
              backgroundColor: "#111",
              border: "2px solid #fff",
              borderRadius: "10px",
            }}
          >
            <div className="input-container">
              <Slider {...settings}>
                {state?.selectnft?.recipes?.map((item, i) => {
                  return (
                    
                    item?.attributes[11]?.value!="HIDDEN"?
                    <div key={item}>
                      <div
                        className="input-div input-div-2"
                        style={{
                          borderColor: `${
                            item?.attributes[11]?.value == "UNCOMMON"
                              ? "#30ff68"
                              : item?.attributes[11]?.value == "RARE"
                              ? "#d57eff"
                              : item?.attributes[11]?.value == "EPIC"
                              ? "#659bff"
                              : item?.attributes[11]?.value == "LEGENDARY"
                              ? "#f8b723"
                              : "#98989a"
                          }`,
                        }}
                      >
                        <img src={item?.image} />
                        <div className="claim_nft_no">#{item.id}</div>
                      </div>
                    </div>
                    :
                    null
                  );
                })}
              </Slider>
            </div>
            <div
              className="ingredient-div__textContainer"
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "0.5rem",
              }}
            >
              {/* <div className='round'>
              <input
                type='checkbox'
                id='checkbox'
                value={confirmnft}
                onChange={() => setConfirmnft(!confirmnft)}
              />
              <label for='checkbox'></label>
            </div> */}
              <CheckBoxTickBtn
                confirmnft={confirmnft}
                onChange={() => setConfirmnft(!confirmnft)}
              />

              <h3>THESE NFTS WILL BE BURNED IN THE BLENDING PROCESS</h3>
            </div>
          </div>
          <div
            className="upper-div-2 blendNow blendConfirm"
            style={{
              backgroundImage: `url(${selectRecipe?.icon})`,
              border: `3px solid ${selectRecipe?.color}`,
              pointerEvents: !confirmnft ? "none" : "",
            }}
            onClick={() => {
              !confirmnft ? console.log("") :
               setFirstOption(4);
              // start();
              console.log(selectRecipe.id)
              blend()
              // setProgress(true);
            }}
          >
            <h1 className="confirmText">CONFIRM</h1>
            {!confirmnft ? (
              <>
                <div className="gradient-overlay"></div>
                <img src={lock} />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default BlendConfirm;
