const Input = (props) => {
  const InputStyle = {
    backgroundColor: "#fff",
    width: "100%",
    borderRadius: "10px",
    height: "100%",
    padding: "0px 10px",
    outline: "none",
    letterSpacing: "0.5px",
    fontSize: "1.25rem",
    fontFamily: "Varela-Regular",
  };
  return (
    <>
      <input {...props} style={InputStyle} />
    </>
  );
};
export default Input;
