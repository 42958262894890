import { useNavigate } from "react-router-dom";
import "./upperbar.css";
import back from "../../assests/back.svg";
import { BuggattiContext } from "../../context/store";
import { useContext } from "react";

const Upperbar = ({
  setFirstOption,
  firstOption,
  isClaim,
  secondOption,
  setSecondOption,
  isStake,
}) => {
  const navigate = useNavigate();
  const [state, dispatch] = useContext(BuggattiContext);
  return (
    <>
      {isStake ? (
        <div className='Bl_outerdiv'>
          <div className='Bl_navbardiv'>
            <div
              className='Bl_Navigator'
              style={{ cursor: "pointer" }}
              onClick={() => {
                firstOption == 1
                  ? navigate("/")
                  : 
                  dispatch({
                    type: "CLAIM_SELECT",
                    payload: [],
                  });
                  setFirstOption(firstOption - 1);
              }}>
              <img src={back} style={{ width: "50%" }} />
            </div>
            <div
              className={firstOption == 1 ? "Bl_Optiondiv" : "Bl_Red_Optiondiv"}
              style={{
                borderBottom: firstOption == 1 ? "4px solid #02a0db" : "2px solid #02a0db",
              }}
              onClick={() => setFirstOption(1)}>
              <p
                className={
                  firstOption == 1 ? "Bl_Nav_Text" : "Bl_Nav_Red_Text"
                }>
                STAKE YOUR NFT
              </p>
            </div>
            <div
              className={firstOption == 2 ? "Bl_Optiondiv" : "Bl_Red_Optiondiv"}
              style={{
                borderBottom: firstOption == 2 ? "4px solid #02a0db" : "2px solid #02a0db",
              }}
              onClick={() => setFirstOption(2)}>
              <p
                className={
                  firstOption == 2 ? "Bl_Nav_Text" : "Bl_Nav_Red_Text"
                }>
                UNSTAKE YOUR NFT
              </p>
            </div>

            <div
              className={firstOption == 3 ? "Bl_Optiondiv" : "Bl_Red_Optiondiv"}
              style={{
                borderBottom: firstOption == 3 ? "4px solid #02a0db" : "2px solid #02a0db",
              }}
              onClick={() => setFirstOption(3)}>
              <p
                className={
                  firstOption == 3 ? "Bl_Nav_Text" : "Bl_Nav_Red_Text"
                }>
                LEADERBOARDS
              </p>
            </div>
          </div>
        </div>
      ) : !isClaim ? (
        <div className='Bl_outerdiv'>
          <div className='Bl_navbardiv'>
            <div
              className='Bl_Navigator'
              onClick={() => {
                   state?.progress?
                  <></>:
                firstOption == 1
                  ? navigate("/")
                  : setFirstOption(firstOption - 1);
              }}
              style={{ cursor: "pointer" }}>
              {/* <p className='Bl_arrow' >&lt;</p> */}
              <img src={back} style={{ width: "50%" }} />
            </div>
            <div
              className={firstOption == 1 ? "Bl_Optiondiv" : "Bl_Red_Optiondiv"}
              style={{
                borderBottom: firstOption == 1 ? "4px solid #02a0db" : "none",
              }}>
              <p
                className={
                  firstOption == 1 ? "Bl_Nav_Text" : "Bl_Nav_Red_Text"
                }>
                SELECT A RECIPE
              </p>
            </div>
            <div
              className={firstOption == 2 ? "Bl_Optiondiv" : "Bl_Red_Optiondiv"}
              style={{
                borderBottom: firstOption == 2 ? "4px solid #02a0db" : "none",
              }}>
              <p
                className={
                  firstOption == 2 ? "Bl_Nav_Text" : "Bl_Nav_Red_Text"
                }>
                BLEND FACTORY
              </p>
            </div>
            <div
              className={
                firstOption == 3 || firstOption == 4
                  ? "Bl_Optiondiv"
                  : "Bl_Red_Optiondiv"
              }
              style={{
                borderBottom: firstOption == 3 ? "4px solid #02a0db" : "none",
              }}>
              <p
                className={
                  firstOption == 3 || firstOption == 4
                    ? "Bl_Nav_Text"
                    : "Bl_Nav_Red_Text"
                }>
                CONFIRM
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className='Bl_outerdiv'>
          <div className='Bl_navbardiv'>
            <div
              className='Bl_Navigator'
              style={{ cursor: "pointer" }}
              onClick={() => {
                  state?.progress?
                  <></>:
                secondOption == 1
                  ? navigate("/")
                  : setSecondOption(secondOption - 1);
              }}>
              {/* <img src={}/> */}
              <img src={back} style={{ width: "50%" }} />
              {/* <p className='Bl_arrow'></p> */}
            </div>
            <div
              style={{
                borderBottom: secondOption == 1 ? "4px solid #02a0db" : "none",
              }}
              className={
                secondOption == 1 ? "Cl_Optiondiv" : "Cl_Red_Optiondiv"
              }>
              <p
                className={
                  secondOption == 1 ? "Cl_Nav_Text" : "Cl_Nav_Red_Text"
                }>
                SELECT YOUR NFTs
              </p>
            </div>
            <div
              style={{
                borderBottom: secondOption == 2 ? "4px solid #02a0db" : "none",
              }}
              className={
                secondOption == 2 ? "Cl_Optiondiv" : "Cl_Red_Optiondiv"
              }>
              <p
                className={
                  secondOption == 2 ? "Cl_Nav_Text" : "Cl_Nav_Red_Text"
                }>
                SELECT PRODUCT
              </p>
            </div>
            <div
              style={{
                borderBottom: secondOption == 3 ? "4px solid #02a0db" : "none",
              }}
              className={
                secondOption == 3 ? "Cl_Optiondiv" : "Cl_Red_Optiondiv"
              }>
              <p
                className={
                  secondOption == 3 ? "Cl_Nav_Text" : "Cl_Nav_Red_Text"
                }>
                SHIPPING FORM
              </p>
            </div>
            <div
              style={{
                borderBottom: secondOption == 4 ? "4px solid #02a0db" : "none",
              }}
              className={
                secondOption == 4 || secondOption == 5
                  ? "Cl_Optiondiv"
                  : "Cl_Red_Optiondiv"
              }>
              <p
                className={
                  secondOption == 4 || secondOption == 5
                    ? "Cl_Nav_Text"
                    : "Cl_Nav_Red_Text"
                }>
                CONFIRM
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Upperbar;
