import { useContext, useState, useEffect } from "react";
import Button from "../button/button";
import { SelectNft } from "../nftcard/selectnft";
import { ClaimBtn } from "../button/claimBtn";
import Slider from "react-slick";
import { BuggattiContext } from "../../context/store";
import axios from "axios";
import useMediaQuery from "../media-query";
import "./confirm_box.css";
import Web3 from "web3";
import { NftContractAddress as contractAddress } from "../../config/constants";
import contractAbi from "../../config/abi.json";
import AWS from "aws-sdk";
const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 8,
  slidesToScroll: 1,
  arrow: true,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        arrow: true,
      },
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        arrow: true,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        arrow: true,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrow: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrow: true,
      },
    },
  ],
};

const ConfirmBox = ({
  setSecondOption,
  status,
  setProgressload,
  setStatus,
  secondOption,
}) => {
  const [finalclaim, setFinalclaim] = useState(false);
  const [state, dispatch] = useContext(BuggattiContext);

  useEffect(() => {
    setFinalclaim(false);
  }, [secondOption]);

  async function claim() {
    dispatch({
      type: "PROGRESS",
      payload: true,
    });
    setSecondOption(5);
    const nftArr = [];
    state?.claimdata?.forEach((item) => {
      nftArr.push(item.id);
    });

    const hashedMessage = Web3.utils.soliditySha3(
      { type: "address", value: state.web3.walletAddress },
      { type: "uint256[]", value: nftArr }
    );

    let contract = await new state.web3.walletInstance.eth.Contract(
      contractAbi,
      contractAddress
    );

    state.web3.walletInstance.eth.personal
      .sign(hashedMessage, state.web3.walletAddress)
      .then((authSignature) => {
        dispatch({
          type: "LOADER",
          payload: 20,
        });
        var params = {
          walletAddress: state.web3.walletAddress,
          nfts: nftArr,
          authSignature: authSignature,
        };

        AWS.config.region = "us-east-1"; // Region
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: "us-east-1:348c044f-13ff-426e-9cf8-3022ec10b684",
        });
        var lambda = new AWS.Lambda();
        lambda
          .invoke({
            FunctionName: "bugatti-claims-signature",
            Payload: JSON.stringify(params),
          })
          .promise()
          .then((res) => {
            var output = JSON.parse(res.Payload);
            if (output.error != null) {
              console.log(output);
              setStatus("ERROR");
            } else {
              dispatch({
                type: "LOADER",
                payload: 60,
              });

              // CONTRACT CLAIMS CALL HERE
              contract.methods
                .claim(nftArr, output)
                .send(
                  { from: state?.web3?.walletAddress },
                  function (error, tx) {
                    if (error) {
                      console.log(error);
                      setStatus("ERROR");
                    } else if (tx) {
                      dispatch({
                        type: "LOADER",
                        payload: 80,
                      });

                      let data = state?.confirmclaim?.shippingdata;
                      let data2 = state?.confirmclaim?.productDetails;
                      // let address = data?.address + " " + data?.city + " " + data?.country + " " + data?.zip;

                      let body = {
                        type: "claim",
                        firstName: data?.firstName,
                        lastName: data?.lastName,
                        mobileNumber: data?.mobileNumber,
                        address: data?.address,
                        city: data?.city,
                        country: data?.country,
                        zip: data?.zip,
                        date: "12/12/2020",
                        walletAddress: state?.web3?.walletAddress,
                        email: data?.email,
                        quantity: data2?.length,
                        productDetails: state?.confirmclaim?.productDetails,
                      };

                      axios
                        .post(
                          "https://ytycn9jc0f.execute-api.us-east-1.amazonaws.com/production/submit",
                          body
                        )
                        .then((res) => {
                          dispatch({
                            type: "LOADER",
                            payload: 100,
                          });

                          dispatch({
                            type: "TRANSACTION_HASH",
                            payload: tx
                          });

                          setStatus("SUCCESS");
                        })
                        .catch((e) => {
                          console.log(e);
                          setStatus("ERROR");
                        });
                    }
                  }
                );
            }
          })
          .catch((err) => {
            setStatus("ERROR");
            console.log(err);
          });
      })
      .catch((err) => {
        setStatus("ERROR");
        console.log(err);
      });
  }

  const isMobile = useMediaQuery("(max-width:1100px)");

  return (
    <>
      <div
        style={{
          display: secondOption == 4 ? "flex" : "none",
          justifyContent: "center",
          width: "100%",
        }}>
        {!finalclaim ? (
          <>
            <div
              className='confirm_box shipping_form_box'
              style={{ padding: 0 }}>
              <div
                className='claim_sum'
                style={{
                  width: "60%",
                }}>
                <h3>CLAIM ORDER SUMMARY</h3>
                <div className='claim_sum_2'>
                  <div
                    className='claim_recipe'
                    style={{ minHeight: isMobile ? "auto" : "170px" }}>
                    {state?.confirmclaim?.productDetails?.map((i) => (
                      <div>
                        <p>1 x {i?.claimType}</p>
                      </div>
                    ))}
                  </div>
                </div>
                {!isMobile ? (
                  <div className='shipBtnWrapper'>
                    <div className='ship_btn_div'>
                      <Button
                        name='CONFIRM'
                        blue={true}
                        onClick={() => setFinalclaim(true)}
                        green={false}
                      />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className='detail_box'>
                <h3>SHIPPING ADDRESS </h3>

                <div style={{ marginTop: "30px" }}>
                  <div className='multi-input'>
                    <div>
                      <p>
                        First Name:{" "}
                        <span>
                          {state?.confirmclaim?.shippingdata?.firstName}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p>
                        Last Name:{" "}
                        <span>
                          {state?.confirmclaim?.shippingdata?.lastName}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='single_input'>
                    <p>
                      Email:{" "}
                      <span> {state?.confirmclaim?.shippingdata?.email}</span>
                    </p>
                  </div>
                  <div className='single_input'>
                    <p>
                      Phone number:{" "}
                      <span>
                        {state?.confirmclaim?.shippingdata?.mobileNumber}
                      </span>
                    </p>
                  </div>
                  <div className='single_input'>
                    <p>
                      Address:{" "}
                      <span>{state?.confirmclaim?.shippingdata?.address}</span>
                    </p>
                  </div>
                  <div className='multi-input'>
                    <div>
                      <p>
                        City:{" "}
                        <span>{state?.confirmclaim?.shippingdata?.city}</span>
                      </p>
                    </div>
                    <div>
                      <p>
                        Country:{" "}
                        <span>
                          {state?.confirmclaim?.shippingdata?.country}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className='single_input'>
                    <p>
                      Zip Code:{" "}
                      <span>{state?.confirmclaim?.shippingdata?.zip}</span>
                    </p>
                  </div>
                  {isMobile ? (
                    <div className='ship_btn_div'>
                      <Button
                        name='CONFIRM'
                        blue={true}
                        onClick={() => {
                          setFinalclaim(true);
                        }}
                        green={false}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ width: "80%", marginTop: "2.5rem" }}>
              <div
                className='selecting_nfts selcet_nft_final'
                style={{
                  width: "100%",
                  backgroundColor: "rgb(17, 17, 17)",
                }}>
                <Slider {...settings}>
                  {state?.claimdata?.map((i) => (
                    <SelectNft
                      available={i?.id}
                      isImage={i?.image}
                      color={i?.attributes[11]?.value}
                    />
                  ))}
                </Slider>
              </div>
              <div className='claim_info claim_confirm_info'>
                <div className='claim_info__buttonDiv confirmBoxButton'>
                  <ClaimBtn name='Confirm' onClick={claim} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ConfirmBox;
