import { useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Footer from "../component/footer/Footer";
import { BuggattiContext } from "../context/store";
import Blend from "../Pages/Blend";
import { Claim } from "../Pages/Claim";
import Homepage from "../Pages/Homepage";
import Stake from "../Pages/stake";

const AppRoutes = () => {
  const [state] = useContext(BuggattiContext)
 
  return (
    // <div>
    <BrowserRouter>
      <Routes>       
        <Route path='/' element={<Homepage />} />
        <Route path='/dashboard/blend' element={state?.walletconnect===true?<Blend />:<Navigate replace to='/'/>} />
        <Route path='/dashboard/stake' element={state?.walletconnect===true?<Stake />:<Navigate replace to='/'/>} />
        <Route path='/dashboard/claim' element={state?.walletconnect===true?<Claim />:<Navigate replace to='/'/>} />
      </Routes>

      <div
        style={{
          paddingBottom: "5rem",
        }}
      />
      <Footer />
    </BrowserRouter>
  );
};
export default AppRoutes;
