import lock from "../../updatedassets/nftLock.svg";
import { useEffect, useState } from "react";
import CheckBoxBtn from "../checkBoxBtn/CheckBoxBtn";
import "./nftcard.css";
import useMediaQuery from "../media-query";

const StakeNftCard = (props) => {
  const [remaining, setRemaining] = useState("");
  const [totalTime, setTotalTime] = useState("");
  const [timeUp, setTimeUp] = useState(false);
  //  (props.color)
  useEffect(() => {
    if (props.unstake == true) {
      setInterval(() => {
        let h2 = timeTotal(
          Date.now() / 1000,
          parseInt(props.stakedTime)
        );
        setTotalTime(h2);

        let h = timeDiff(
          Date.now() / 1000,
          parseInt(props.stakedTime) + parseInt(props.minDuration)
        );
        setRemaining(h);
      }, 1000);
    }
  });

  function timeDiff(tstart, tend) {
    var differenceDate = new Date(Math.floor(tend - tstart));
    if (differenceDate <= 0) {
      setTimeUp(true);
    }
    var days = Math.floor(differenceDate / 86400);
    var hours = Math.floor(differenceDate / 3600) % 24;
    var minutes = Math.floor(differenceDate / 60) % 60;
    var seconds = differenceDate % 60;
    var dayString = days==0?"":`${days} days `
    var hoursString = hours==0?"":`${hours} hours `
    var minutesString = minutes==0?"":`${minutes} minutes `
    var secondsString = seconds==0?"":`${seconds} seconds`
    var s = dayString!=""?`${dayString}${hoursString}`:hoursString!=""?`${hoursString}${minutesString}`:`${minutesString}${secondsString}`
    return s;
  }

  function timeTotal(now, timeStaked) {
    var differenceDate = new Date(Math.floor(now - timeStaked));
    var days = Math.floor(differenceDate / 86400);
    var hours = Math.floor(differenceDate / 3600) % 24;
    var minutes = Math.floor(differenceDate / 60) % 60;
    var seconds = differenceDate % 60;
    var dayString = days==0?"":`${days} days `
    var hoursString = hours==0?"":`${hours} hours `
    var minutesString = minutes==0?"":`${minutes} minutes `
    var secondsString = seconds==0?"":`${seconds} seconds`
    var s = dayString!=""?`${dayString}${hoursString}`:hoursString!=""?`${hoursString}${minutesString}`:`${minutesString}${secondsString}`
    return s;
  }

  const mediaquery = useMediaQuery('(max-width: 600px)')
// console.log(mediaquery)
  return (
    <div>
      <div
        className='input-div'
        style={{
          // height: "150px",
          margin:mediaquery?"0px 15px":"0px 8px",
          borderRadius: "5px 5px 0 0",
          width: "auto !important",
          borderColor: `${
            props?.color == "UNCOMMON"
              ? "#30ff68"
              : props?.color == "RARE"
              ? "#d57eff"
              : props?.color == "EPIC"
              ? "#659bff"
              : props?.color == "LEGENDARY"
              ? "#f8b723"
              : "#98989a"
          }`,
        }}>
        <img src={props.isImage} height='100%' width='100%' alt='' />

        <div className='claim_nft_no' style={{ borderStartStartRadius: "7px" }}> #{props.id} </div>

        {timeUp ? (
          props.checkbox ? (
            <div className='claim_nft_chkbox'>
              {/* <input
              type='checkbox'
              onChange={() => props.handleChange(props.id)}></input> */}
              <CheckBoxBtn
                name={"unstakeBtn"}
                id={props.id}
                color='#12141D'
                onChange={() => props.handleChange(props.id)}
              />
            </div>
            
          ) : (
            ""
          )
        ) : (
          <></>
        )}

      </div>
      
      {timeUp == false && (
        <div
          className='bottom_stake'
          style={{
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            margin:mediaquery?"0px 15px":"0px 8px",
          }}>
          <p>{remaining} </p>
          <img src={lock} style={{ width: "20px" }} />
        </div>
      )}

      {timeUp == true && (
        <div
          className='bottom_stake'
          style={{
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            margin:mediaquery?"0px 15px":"0px 8px",
          }}>
          <p>{totalTime} </p>
          {/* <img src={lock} style={{ width: "20px" }} /> */}
        </div>
      )}

    </div>
  );
};
export default StakeNftCard;
