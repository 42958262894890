import './loader.css'
export const Loader = () =>{
    
    return(
        <>
         <div className='loading'>
            <div></div>
        </div>
        </>
    )
}

export const SmallLoader = () =>{
    return(
        <>
         <div className='small'>
            <div className='small-div'></div>
        </div>
        </>
    )
}

export const ImageLoader = () =>{
    return(
        <>
         <div className='imageSmall'>
            <div className='image-small-div'></div>
        </div>
        </>
    )
}

export const MediumLoader = () =>{
    return(
        <>
         <div className='meium'>
            <div className='medium-div'></div>
        </div>
        </>
    )
}