import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BuggattiContext } from "../../context/store";
import Button from "../button/button";

const Progress_bar = ({status}) => {
  // let progress = 10;
  // const [progres, setProgress] = useState(0);
  const [state, dispatch] = useContext(BuggattiContext);
  const navigate = useNavigate();

  // const load = () => {
  //   let count = 0;
  //   var intervel = setInterval(function () {
  //     if (count > 4) {
  //       setProgressload(false);
  //       clearInterval(intervel);
  //       intervel = undefined;
  //       
  //     } else {
  //       setProgress((prev) => prev + 10);
  //       count++;
  //     }
  //   }, 1000);
  // };

  const Parentdiv = {
    height: 70,
    width: "95%",
    backgroundColor: "#000",
    borderRadius: 5,
    border: "1px solid #fff",
  };

  let Childdiv = {
    height: "100%",
    width: `${state?.loader}%`,
    backgroundColor: "#FFF",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "1.5s ease",
    // overflow:'hidden'
  };

  const progresstext = {
    color: state?.loader === 0 ? "#222637" : "#12141d",
    fontWeight: "bold",
    fontSize: "4.5rem",
    fontFamily: "Anton",
    display: state?.loader <= 5 ? "none" : "block",
  };
  const error_div = {
    backgroundColor: "#ff5757",
    height: 70,
    width: "95%",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: 7,
  };
  const success_div = {
    backgroundColor: "#2fef64",
    height: 70,
    width: "95%",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: 7,
  };
  return (
    <>
      {status == 'ERROR' || status == 'SUCCESS' ? (
<>
{status == 'ERROR'?
<>
        <div style={error_div}>
          <h3
            style={{
              fontWeight: "400",
              fontSize: "clamp(1.8rem, 1.1vw, 1.3rem)",
            }}>
            TRANSACTION FAILED
          </h3>
          {/* <p style={{ fontStyle: "italic" }}>ERROR: SOMETHING went wrong</p> */}
        </div>
        <div style={{marginTop:"20px"}}>
        <Button
        name='BACK TO DASHBOARD'
        blue={true}
        green={false}
        onClick={() =>{ 
          
          // navigate('/');
          window.location.replace("/")
         dispatch({
          type: "PROGRESS",
          payload: false,
        })
        dispatch({
          type: "LOADER",
          payload: 0,
        })}}
        />
      </div>
      </>
        :
        status == 'SUCCESS'?
        <>
         <div style={success_div}>
          <h3
            style={{
              fontWeight: "400",
              fontSize: "clamp(1.8rem, 1.1vw, 1.3rem)",
            }}>
            TRANSACTION SENT
          </h3>
          <a  target='_blank' href={`https://etherscan.io/tx/${state.transactionHash}`}>
          <p style={{ fontStyle: "italic" }}>View Transaction Details</p>
          </a>
        </div>
        <div style={{marginTop:"20px"}}>
        <Button
        name='BACK TO DASHBOARD'
        blue={true}
        onClick={() =>{ 
          // navigate('/');
          
          window.location.replace("/")
           dispatch({
          type: "PROGRESS",
          payload: false,
        })
        dispatch({
          type: "LOADER",
          payload: 0,
        })}}
        green={false}
        />
      </div>
      </>
        :
        <></>
}

</>
) : (
        <div style={Parentdiv}>
          <div style={Childdiv}>
            <span style={progresstext} className='progresstest'>{`${state?.loader}%`}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default Progress_bar;
