import logo from "../../assests/logo.png";

const Navbar = () => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "7vh",
          marginBottom: "25px",
        }}>
        <div style={{ width: "95%", marginTop: "10px", height: "100%" }}>
          {/* <h1 style={{color:'#fff'}}>NFT</h1> */}
          <img src={logo} style={{ width: "70px", cursor: "pointer" }} />
        </div>
      </div>
    </>
  );
};
export default Navbar;
