import React, { useContext, useEffect } from "react";
import Button from "../component/button/button";
import lock from "../assests/lock.svg";
import { useNavigate } from "react-router-dom";
import Navbar from "../component/navbar/navbar";
import Web3Modals from "web3modal";
import Web3 from "web3";
import { useState } from "react";
import WalletConnectProvider from "@walletconnect/web3-provider";
import AWS from "aws-sdk";
import { BuggattiContext } from "../context/store";
import abi from "../config/abi.json";
import stakingAbi from "../config/stakingAbi.json"
import Footer from "../component/footer/Footer";
import useMediaQuery from "../component/media-query";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import "./Homepage.css";

import {
  NftContractAddress as contractAddress,
  chainId,
  infura,
  StakingContractAddress
} from "../config/constants";

const Homepage = () => {
  const navigate = useNavigate();

  let web3Modal;
  var provider = "";

  const [walletAddress, setWalletAddress] = useState("");
  const [connectbtn, setConnectbtn] = useState("Wallet Connect");
  const [blendsEnabled, setBlendsEnabled] = useState(false);
  const [claimsEnabled, setClaimsEnabled] = useState(false);
  const [hover, setHover] = useState(false);
  const [state, dispatch] = useContext(BuggattiContext);
  useEffect(() => {
    state?.walletconnect
      ? setConnectbtn("Connected")
      : setConnectbtn("CONNECT WALLET");
  }, [state]);
  async function onConnect() {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: infura,
        },
      },
      coinbasewallet: {
        package: CoinbaseWalletSDK,
        options: {
          infuraId: infura,
        },
      },
    };

    web3Modal = new Web3Modals({
      cacheProvider: false,
      providerOptions,
      disableInjectedProvider: false,
    });

    if (web3Modal.cachedProvider) {
      web3Modal.clearCachedProvider();
    }
    try {
      provider = await web3Modal.connect();
    } catch (e) {
      console.log("Could not get a wallet connection", e);
      return;
    }
    provider.on("accountsChanged", (accounts) => {
      init(provider);
    });
    provider.on("chainChanged", (chainId) => {
      init(provider);
    });
    provider.on("disconnect", (error) => {
      setWalletAddress("");
    });
    init(provider);
  }
  let web3;
  let accounts;
  AWS.config.region = "us-east-1"; // Region
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "us-east-1:348c044f-13ff-426e-9cf8-3022ec10b684",
  });

  async function init(provider) {
    setConnectbtn("Connecting");
    web3 = new Web3(provider);

    web3.eth.getChainId().then(async (chain_id) => {
      if (chain_id != chainId) {
        web3.currentProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: Web3.utils.toHex(chainId) }],
        });
      } else {
        accounts = await web3.eth.getAccounts();
        let contractInstance = new web3.eth.Contract(abi, contractAddress);
        let stakingContract = new web3.eth.Contract(
          stakingAbi,
          StakingContractAddress
        );
        await loadContractData(contractInstance, accounts[0], web3);

        async function loadContractData(
          _contractInstance,
          address,
          walletInstance
        ) {
          let isPaused = await _contractInstance.methods.paused().call();
          let bEnabled = await _contractInstance.methods.blendsEnabled().call();
          let cEnabled = await _contractInstance.methods.claimsEnabled().call();
          let sEnabled = await stakingContract.methods.paused().call()
          if (!isPaused) {
            if (bEnabled || cEnabled) {

              let recipe = [
                parseInt(await _contractInstance.methods.recipeCaps(1).call()),
                parseInt(await _contractInstance.methods.recipeCaps(2).call()),
                parseInt(await _contractInstance.methods.recipeCaps(3).call()),
                parseInt(await _contractInstance.methods.recipeCaps(4).call()),
                parseInt(await _contractInstance.methods.recipeCaps(5).call()),
              ];
              let tokensOfOwner = await _contractInstance.methods
                .tokensOfOwner(address)
                .call();
              const params = {
                FunctionName: "bugatti-get-metadata-multiple",
                Payload: JSON.stringify({
                  id: tokensOfOwner,
                }),
              };
              var lambda = new AWS.Lambda();
              lambda.invoke(params).promise().then(res => {
                var output = JSON.parse(res.Payload);
                if (output.error != null) {
                  console.log(output);
                  onDisconnect()
                } else {
                  dispatch({
                    type: "DATA",
                    payload: { data: output },
                  });
                  dispatch({
                    type: "RECIPE_AVAILABLE",
                    payload: recipe,
                  });
                  dispatch({
                    type: "WEB3",
                    payload: {
                      walletAddress: address,
                      bugattiContract: _contractInstance,
                      walletInstance: walletInstance,
                    },
                  });
                  dispatch({
                    type: "WALLET_CONNECT",
                    payload: true,
                  });


                  dispatch({
                    type: "BLEND_ENABLED",
                    payload: bEnabled,
                  });

                  dispatch({
                    type: "CLAIM_ENABLED",
                    payload: cEnabled,
                  });
                  dispatch({
                    type: "STAKE_ENABLED",
                    payload: !sEnabled,
                  });

                  setConnectbtn("Connected");
                  setWalletAddress(address);

                  setBlendsEnabled(bEnabled);

                  setClaimsEnabled(cEnabled);

                }
              })
                .catch(err => {
                  console.log(err)

                  onDisconnect()
                })
            }

            else {
              dispatch({
                type: "WEB3",
                payload: {
                  walletAddress: address,
                  bugattiContract: _contractInstance,
                  walletInstance: walletInstance,
                },
              });
              setConnectbtn("Connected");
              setWalletAddress(address);

              dispatch({
                type: "WALLET_CONNECT",
                payload: true,
              });

            }
          }

          return "load Succesfull";
        }
      }
    });
  }

  async function onDisconnect() {
    if (provider.close) {
      await provider.close();
      await web3Modal.current.clearCachedProvider();
      setWalletAddress(null);
    }
    dispatch({
      type: "WALLET_CONNECT",
      payload: false,
    });
    dispatch({
      type: "WEB3",
      payload: {
        walletAddress: null,
        bugattiContract: null,
        walletInstance: null,
      },
    });
  }
  const textstring = (text) => {
    if (text?.length > 5) {
      return text?.substring(0, 16) + "..";
    } else {
      return text;
    }
  };
  const isMobile = useMediaQuery("(max-width: 600px)");

  return (
    <>
      <div>
        <Navbar />
        <div className='H_outerdiv'>
          <div className='home_innerdiv'>
            <div className='home_innerdiv__connectBtnDiv'>
              {connectbtn == "Connected" ? (
                <Button
                  name={
                    isMobile
                      ? textstring(state?.web3?.walletAddress)
                      : state?.web3?.walletAddress
                  }
                  green={"true"}
                  onClick={() => onDisconnect()}
                />
              ) : (
                <Button
                  name={connectbtn}
                  white={"true"}
                  onClick={() => onConnect()}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  hover={hover ? "true" : "false"}
                />
              )}
            </div>
            <div className='homeWrapper'>
              <div className='home_box'>
                {/* BLEND */}
                <div
                  className='blend_box'
                  onClick={() => {
                    !state?.walletconnect || state?.blendEnable == false
                      ? console.log("")
                      : navigate("/dashboard/blend");
                  }}>
                  {!state?.walletconnect || state?.blendEnable == false ? (
                    <>
                      <div className='gradient-overlay'></div>
                      <img src={lock} />
                    </>
                  ) : (
                    <></>
                  )}
                  <div className='blend_box_inner'>
                    <h2>
                      <span>BLEND</span>
                      YOUR NFTS
                    </h2>
                  </div>
                </div>
                {/* CLAIM */}
                <div
                  className='claim_box'
                  onClick={() =>
                    !state?.walletconnect || state?.claimEnable == false
                      ? console.log("")
                      : navigate("/dashboard/claim")
                  }>
                  {!state?.walletconnect || state?.claimEnable == false ? (
                    <>
                      <div className='gradient-overlay'></div>
                      <img src={lock} />
                    </>
                  ) : (
                    <></>
                  )}
                  <div className='blend_box_inner'>
                    <h2>
                      <span>CLAIM</span>
                      PRODUCTS
                    </h2>
                  </div>
                </div>
              </div>

              <div className='home_box' style={{ marginTop: "30px" }}>
                {/* STAKE */}
                <div
                  className='blend_box stake_box'
                  onClick={() =>
                    !state?.walletconnect
                      ? console.log("")
                      :
                      // console.log("disabled")
                      navigate("/dashboard/stake")
                  }>
                  {!state?.walletconnect ? (
                    <>
                      <div className='gradient-overlay'></div>
                      <img src={lock} />
                    </>
                  ) : (
                    <></>
                  )}
                  <div className='blend_box_inner'>
                    <h2>
                      <span>STAKE</span>
                      YOUR NFTS
                    </h2>
                  </div>
                </div>

                {/* STORE */}
                  <div className='claim_box comming-box' onClick={() => window.open("https://store.bugatticollectionsnft.com/", '_blank')}>

                    <div className='gradient-overlay'></div>
                    {/* <img src={lock} /> */}
                    <div className='blend_box_inner'>
                      <h2>
                        <span>VISIT</span>
                        THE STORE
                      </h2>
                    </div>
                  </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Homepage;