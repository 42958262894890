import "./blend_factory.css";
import plus from "../../assests/plus.svg";
import { useContext, useEffect, useState } from "react";
import lock from "../../assests/lock.svg";
import Slider from "react-slick";
import one from "../../assests/1.png";
import two from "../../assests/3 (1).png";
import three from "../../assests/3 (2).png";
import cross from "../../assests/cross.svg";
import uncommon from "../../updatedassets/raritiesPlus/uncommon.svg";
import epic from "../../updatedassets/raritiesPlus/epic.svg";
import legendary from "../../updatedassets/raritiesPlus/legendary.svg";
import rare from "../../updatedassets/raritiesPlus/rare.svg";
import common from "../../updatedassets/raritiesPlus/common.svg";
import nftlock from "../../updatedassets/nftLock.svg";
import { BuggattiContext } from "../../context/store";
import { Loader } from "../loader/loader";
import dummyImage from "../../assests/1.png";
import ProgressiveImage from "react-progressive-graceful-image";
import {  blend_factory_settings } from "../../utils/utils--blends";

const BlendFactory = ({
  setFirstOption,
  selectRecipe,
  data,
  firstOption,
}) => {
  const [selectInput, setSelectInput] = useState(0);
  const [allnfts, setAllnfts] = useState([]);
  const [nftType, setNftType] = useState("COMMON");
  const [recipes, setRecipe] = useState(recipe);
  const [isHover, setIsHover] = useState(false);
  const [isHovers, setIsHovers] = useState(false);
  const [state,dispatch] = useContext(BuggattiContext);
  const [loader, setLoader] = useState(true);
  const [imgLoading, setImgLoading] = useState(false);

  useEffect(() => {
    setAllnfts(data?.data);
  }, [data]);
  useEffect(() => {
    if (firstOption == 2) {
      setSelectInput(0);
      shownft("COMMON");
    }
  }, [firstOption]);
  const allRecipes = {
    common: "",
    UNCOMMON: ["common", "common"],
    RARE: ["common", "common", "common", "uncommon"],
    EPIC: [
      "common",
      "common",
      "common",
      "common",
      "uncommon",
      "uncommon",
      "uncommon",
      "uncommon",
    ],
    LEGENDARY: ["common", "uncommon", "rare", "rare", "epic", "epic", "epic"],
    MYTHIC: [
      "common",
      "uncommon",
      "rare",
      "rare",
      "epic",
      "legendary",
      "legendary",
    ],
  };
  

  // Selected Recipie
  var recipe = allRecipes[selectRecipe?.type];
  useEffect(() => {
    setRecipe(recipe);
    setTimeout(() => {
      setLoader(false);
    }, 3000);
  }, [selectRecipe]);


  const shownft = (item) => {
    if (typeof item == "object") {
      setNftType(item?.attributes[11]?.value);
      setAllnfts(
        data?.data?.filter(
          (i) => i?.attributes[11]?.value == item?.attributes[11]?.value
        )
      );
    } else {
      setNftType(item.toUpperCase());
      setAllnfts(
        data?.data?.filter((i) => i?.attributes[11].value == item.toUpperCase())
      );
    }
  };
  const addNFT = (item) => {
    recipes[selectInput] = item;
    setRecipe(recipes);
    if (selectInput + 1 == recipes?.length) {
      setSelectInput(selectInput);
      shownft(recipes[selectInput]);
    } else {
      setSelectInput(selectInput + 1);
      shownft(recipes[selectInput + 1]);
    }
  };
  const cancelNft = (item, index) => {
    var temp = item?.attributes[11]?.value;
    recipes[index] = temp.toLowerCase();
    setRecipe(recipes);
    if (selectInput + 1 == recipes?.length) {
      setSelectInput(selectInput);
    } else {
      setSelectInput(selectInput + 1);
    }
  };
  const blend_nft = () => {
    recipes?.filter((i) => typeof i == "object").length != recipes?.length
      ? console.log("")
      : dispatch({
        type: "CONFIRM_NFT",
        payload: { recipes },
      });
    setFirstOption(3);
  };
  const getbordercolor = (item) => {
    if(item == "common") return "#98989a"
    else if(item == "rare") return "#d57eff"
    else if(item == "epic") return "#659bff"
    else if(item == "legendary") return "#f8b723"
    else return "#30ff68"
  }

  return (
    <>
      <div
        className='blend-factory'
        style={{ display: firstOption == 2 ? "flex" : "none" }}>
        <div className='upper-div'>
          <div className='ingredient-div' style={{ minHeight: "200px" }}>
            <h3>
              <span style={{ color: selectRecipe?.color }}>
                {selectRecipe?.type}
              </span>{" "}
              NFT INGREDIENT
            </h3>
            <div className='input-container'>
              <Slider {...blend_factory_settings}>
                {recipes?.map((item, index) => {
                  return (
                    <div key={item}>
                      <div
                        className='input-div input-div-1'
                        onClick={() => {
                          setSelectInput(index);
                          shownft(item);
                        }}
                        onMouseEnter={() => setIsHover(index)}
                        onMouseLeave={() => setIsHover()}
                        style={{
                          transition: "transform 0.2s ease-in-out",
                          transform: isHover === index ? "scale(1.05)" : "none",
                          border: `${item?.image
                            ? `4px solid ${getbordercolor(item["attributes"][11].value.toLowerCase())}`
                            : `4px solid ${getbordercolor(item)}`
                            }`,

                          boxShadow: `${selectInput == index
                            ? `0px 2px 8px 0px ${typeof item == "string"
                              ? getbordercolor(item)
                              : item == "common" ||
                                item?.attributes[11]?.value == "COMMON"
                                ? "#98989a"
                                : item == "rare" ||
                                  item?.attributes[11]?.value == "RARE"
                                  ? "#d57eff"
                                  : item == "epic" ||
                                    item?.attributes[11]?.value == "EPIC"
                                    ? "#659bff"
                                    : item == "legendary" ||
                                      item?.attributes[11]?.value == "LEGENDARY"
                                      ? "#f8b723"
                                      : "#30ff68"
                            }`
                            : ""
                            }`,
                        }}>
                        {
                          item?.image ? (
                            <>
                              <img src={cross} className="nft-cross"
                              onClick={() => cancelNft(item, index)}
                              />
                              <img src={item?.image} alt='' />
                            </>
                          ) : (
                            // #659bff
                            <img
                              src={
                                item == "common"
                                  ? common
                                  : item == "rare"
                                    ? rare
                                    : item == "epic"
                                      ? epic
                                      : item == "legendary"
                                        ? legendary
                                        : uncommon
                              }
                              alt=''
                            />
                          )
                          // <p >+</p>
                        }
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
          <div
            className='upper-div-2 blendNow'
            style={{
              backgroundImage: `url(${selectRecipe?.icon})`,
              border: `3px solid ${selectRecipe?.color}`,
            }}
            onClick={() =>
              recipes?.filter((i) => typeof i == "object")?.length !=
                recipes?.length
                ? console.log("")
                : blend_nft()
            }>
            {recipes?.filter((i) => typeof i == "object")?.length !=
              recipes?.length ? (
              <>
                <div className='gradient-overlay'></div>
                <img src={lock} />
                {recipes?.length && <h1>BLEND NOW</h1>}
              </>
            ) : (
              <h1 style={{ backgroundImage: "none" }}>BLEND NOW</h1>
            )}
          </div>
        </div>

        <div className='upper-div'>
          <div className='bottom-div'>
            {loader && imgLoading ? (
              <Loader />
            ) : (
              <>
                {allnfts?.length == undefined || allnfts?.length == 0 ? (
                  <></>
                ) : (
                  <h2>
                    SELECT YOUR{" "}
                    <span
                      style={{color:`${getbordercolor(nftType?.toLocaleLowerCase())}`}}>
                      {" "}
                      {nftType}
                    </span>{" "}
                    NFT TO BLEND
                  </h2>
                )}

                <div className='input-container'>
                  {allnfts?.length == undefined || allnfts?.length == 0 ? (
                    <h2 style={{ textAlign: "center", margin: "50px 0px" }}>
                      YOU DONT HAVE ANY{" "}
                      <span
                        style={{color:`${getbordercolor(nftType?.toLocaleLowerCase())}`,}}>
                        {" "}
                        {nftType}
                      </span>{" "}
                      NFT
                    </h2>
                  ) : (
                    <Slider {...blend_factory_settings}>
                      {allnfts?.map((item, i) => (
                        item!="HIDDEN" &&
                        <div key={item}>
                          <div
                            className={
                              recipes?.filter((i) => {
                                return i.id == item.id;
                              }).length == 0
                                ? "input-div"
                                : "input-div select"
                            }
                            key={i}
                            onClick={() => {
                              recipes?.filter((i) => {
                                return i.id == item.id;
                              }).length == 0
                                ? addNFT(item)
                                : console.log();
                            }}
                            onMouseEnter={() => setIsHovers(i)}
                            onMouseLeave={() => setIsHovers()}
                            style={{
                              transition: "transform ease-in-out 0.2s",
                              transform:isHovers === i ? "scale(1.05)" : "scale(1.0)",
                              borderColor: `${getbordercolor(nftType?.toLocaleLowerCase())}`,
                            }}>
                            <h3>#{item?.id}</h3>
                            <ProgressiveImage
                              src={item?.image}
                              placeholder={"Claim Nfts"}>
                              {(src, loading) => {
                                // setImgLoading(loading);
                                return loading ? (
                                  <img
                                    className='image imgLoading'
                                    src={dummyImage}
                                    alt='Claim Nfts'
                                    height='100%'
                                    width='100%'
                                  />
                                ) : (
                                  <img
                                    className='image imgLoaded'
                                    src={src}
                                    alt='Claim Nfts'
                                    height='100%'
                                    width='100%'
                                  />
                                );
                              }}
                            </ProgressiveImage>
                            {recipes?.filter((i) => {
                              return i.id == item.id;
                            }).length == 0 ? (
                              ""
                            ) : (
                              <img className='lock-icon' src={nftlock} />
                            )}
                          </div>
                        </div>
                      ))}
                    </Slider>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default BlendFactory;
