export const reducer = (state, action) => {
  switch (action.type) {
    case "DATA":
      return {
        ...state,
        nftsdata: action.payload,
      };
    case "CONFIRM_NFT":
      return {
        ...state,
        selectnft: action.payload,
      };
    case "WALLET_CONNECT":
      return {
        ...state,
        walletconnect: action.payload,
      };
    case "WEB3":
      return {
        ...state,
        web3: action.payload,
      };
    case "CLAIM_SELECT":
      return {
        ...state,
        claimdata: action.payload,
      };
    case "CLAIM_DETAILS":
      return {
        ...state,
        claimdetails: action.payload,
      };
    case "CONFIRM_CLAIM":
      return {
        ...state,
        confirmclaim: action.payload,
      };
    case "PROGRESS":
      return {
        ...state,
        progress: action.payload,
      };
    case "LOADER":
      return {
        ...state,
        loader: action.payload,
      };
    case "RECIPE_AVAILABLE":
      return {
        ...state,
        recipe: action.payload,
      };
      case "TRANSACTION_HASH":
      return{
        ...state,
        transactionHash:action.payload
      };
      
      case "BLEND_ENABLED":
      return{
        ...state,
        blendEnable:action.payload
      };
      case "CLAIM_ENABLED":
      return{
        ...state,
        claimEnable:action.payload
      };
      
      case "STAKE_ENABLED":
      return{
        ...state,
        stakeEnable:action.payload
      }
  }
};
