import { useState } from "react";
import Navbar from "../component/navbar/navbar";
import Upperbar from "../component/uppperbar/uppperbar";
import StakeNft from "../component/stake_nft/stake_nft";
import LeaderBoard from "../component/leaderboard/leaderboard";
import Unstake from "../component/stake_nft/unstake_nft";
import "./stake.css";

const Stake = () => {
  const [firstOption, setFirstOption] = useState(1);

  return (
    <>
      <div>
        <Navbar />
        <div className='blend-content stake'>
          <Upperbar
            firstOption={firstOption}
            setFirstOption={setFirstOption}
            isStake={true}
          />
          <div
            className='blend-factory stakeNft'
            style={{
              display: firstOption == 1 ? "block" : "none",
              width: "84%",
            }}>
            <StakeNft setFirstOption={setFirstOption} />
          </div>

          <div
            className='blend-factory unStakeNft'
            style={{
              display: firstOption == 2 ? "block" : "none",
              width: "84%",
            }}>
            <Unstake setFirstOption={setFirstOption} />
          </div>

          <div
            className='blend-factory stakeLeaderBoard'
            style={{
              display: firstOption == 3 ? "block" : "none",
              width: "90%",
            }}>
            <LeaderBoard setFirstOption={setFirstOption} />
          </div>
        </div>
      </div>
     
    </>
  );
};
export default Stake;
