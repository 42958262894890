const Crossbody_Card = (props) =>{
    return(
        <>
                     
             <div
                className='second_first'
                style={{backgroundImage:`url(${props?.image})`}}
                onClick={props.onClick}>
                <h3>{props?.head}</h3>
              </div>
              
        </>
    )
}
export default Crossbody_Card