import { forwardRef, useState } from "react";

import Tippy from "@tippyjs/react/headless";
import { FiChevronUp } from "react-icons/fi";

import info from "../../assests/info.png";

import "./recipecard.css";

const RecipeCard = (props) => {
  const [arrow, setArrow] = useState(null);
  const recipeitem = [
    {
      item: ["3 Common"],
    },
    { item: ["3 Common", "1 Uncommon"] },
    { item: ["4 Common", "4 Uncommon"] },
    { item: ["1 Common", "1 Uncommon", "2 Rare", "3 Epic"] },
    { item: ["1 Common", "1 Uncommon", "2 Rare", "1 Epic", "2 Legendary"] },
  ];
  return (
    <div style={{ filter: props?.available == 0 ? "grayscale(1)" : "" }}>
      <div
        className='recipe-outerdiv'
        style={{
          border: `4px solid ${props.color}`,
        }}
        onClick={() => {
          props?.available == 0 ? <></> : props.setFirstOption(2);
          props.setSelectRecipe(props);
        }}>
        <img
          className='recipe-img'
          src={props.icon}
          style={{ width: "75%" }}
          alt=''
        />
        <div className='recipe-bottom'>
          <button style={{ border: `3px solid ${props.color}` }}>
            {props?.type}
          </button>
        </div>
        <div className='recipe-top'>
          <button style={{ border: `2px solid ${"#fff"}` }}>
            Available:&nbsp;{props?.available}
          </button>
        </div>
        {props?.available == 0?  
          <div className='info-div'>
        <img src={info} style={{ width: "100%" }} alt='' />:
        </div>
        :
        <div className='info-div'>
          <Tippy
            placement='bottom'
            distance='200px'
            arrow={true}
            render={(attrs) => (
              <div className='info-div__tooltip'>
                <div
                  className='info-div__tooltip-Box'
                  tabIndex='-1'
                  {...attrs}
                  style={{
                    border: `2px solid ${props.color}`,
                    borderRadius: "5px",
                  }}>
                  <p className='info-div__tooltip-Box-Text'>Recipe</p>
                  <ul>
                    {props?.item.map((i) => (
                      <li key={props.id * Math.random()}>{i}</li>
                    ))}
                  </ul>
                </div>
                <span
                  id='arrow'
                  data-popper-arrow=''
                  className='info-div__tooltip-Box-Icon'>
                  <FiChevronUp
                    ref={arrow}
                    color={props.color}
                    size={20}
                    strokeWidth='2.5'
                  />
                </span>
                <div className='info-div__tooltip-Box-bdElem' />
              </div>
            )}
            popperOptions={{
              modifiers: [
                {
                  name: "arrow",
                  options: {
                    element: arrow, // can be a CSS selector too
                  },
                },
              ],
            }}>
            <img src={info} style={{ width: "100%" }} alt='' />
          </Tippy>
        </div>
        }
      </div>
    </div>
  );
};
export default RecipeCard;
